<template>
  <div class='flex flex-row justify-start items-stretch gap-x-1 py-2'>
    <ipo-search class='flex-grow lg:flex-grow-0' />
    <button v-if='hasSelectedIpo'
      @click='ipoFundLists'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <view-list-icon class='inline-block h-5' style='margin-top: -5px;' />
      참여펀드목록
    </button>
    <button v-if='hasSelectedIpo'
      @click='refreshIpoSummaries'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <refresh-icon class='inline-block h-5' style='margin-top: -5px;' />
      조회
    </button>
    <button v-if='hasSelectedIpo'
      @click='calculate'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <calculator-icon class='inline-block h-5' style='margin-top: -5px;' />
      계산
    </button>
    <button v-if='hasIpoResult'
      @click='makeReports'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <archive-icon class='inline-block h-5' style='margin-top: -5px;' />
      생성
    </button>
    <button v-if='hasIpoResult'
      @click='clearReports'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <trash-icon class='inline-block h-5' style='margin-top: -5px;' />
      삭제
    </button>
    <span v-if='hasIpoResult' class='flex flex-row gap-x-1 ml-12'>
      <a :href='licenseFileUrl'
        download
        class='block border rounded-md font-bold pl-3 pr-4 py-2 hover:underline hover:bg-yellow-300 whitespace-no-wrap'>
        <download-icon class='inline-block h-5' style='margin-top: -5px;' />자격/펀드신고서류</a>
      <a :href='businessLicenseFileUrl'
        download
        class='block border rounded-md font-bold pl-3 pr-4 py-2 hover:underline hover:bg-yellow-300 whitespace-no-wrap'>
        <download-icon class='inline-block h-5' style='margin-top: -5px;' />사업자등록증</a>
      <a v-for='summary in ipoSummaryFiles'
        :key='`ipo-summary-file-${summary.id}`'
        :href='summary.filename'
        download
        class='block border rounded-md font-bold pl-3 pr-4 py-2 hover:underline hover:bg-yellow-300 whitespace-no-wrap'>
        <download-icon class='inline-block h-5' style='margin-top: -5px;' />{{ summary.account_type }}</a>
    </span>
    <portal to='modals'>
      <ipo-fund-lists
        :style='modalSizeStyle' />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { ViewListIcon, RefreshIcon, CalculatorIcon, ArchiveIcon, TrashIcon, DownloadIcon } from '@vue-hero-icons/outline'
import IpoSearch from '@/components/IpoSearch.vue'
import IpoFundLists from '@/views/ipo/IpoFundLists.vue'

export default {
  name: 'IpoLists',
  components: {
    ViewListIcon,
    RefreshIcon,
    CalculatorIcon,
    ArchiveIcon,
    TrashIcon,
    DownloadIcon,
    IpoSearch,
    IpoFundLists,
  },
  computed: {
    ...mapFields('ipos', [
      'selectedIpoId',
      'selectedIpo',
    ]),
    ...mapState('ipos', [
      'ipos',
      'ipoSummaries',
      'ipoSummaryFiles',
      'ipoResults',
      'selectedBrokerId',
      'selectedPredictedDate',
      'selectedSubscriptionDate',
    ]),
    ...mapState([
      'modalSize',
    ]),
    hasSelectedIpo () {
      return (this.selectedIpoId > 0)
    },
    hasIpoResult () {
      return (this.ipoResults.length > 0)
    },
    modalHeight () { // in rem
      const maxHeight = window.innerHeight / 16
      let height = (this.modalSize.height) ? this.modalSize.height : '52' // taken as parameter
      return Math.min(maxHeight, height)
    },
    modalWidth () { // in rem
      const maxWidth = window.innerWidth / 16
      let width = (this.modalSize.width) ? this.modalSize.width : '34' // taken as parameter
      return Math.min(maxWidth, width)
    },
    modalSizeStyle () {
      return `height: ${this.modalHeight}rem; width: ${this.modalWidth}rem`
    },
    licenseFileUrl () {
      return this.ipoSummaries.length > 0 ? this.ipoSummaries[0].license_file : ''
    },
    businessLicenseFileUrl () {
      return this.ipoSummaries.length > 0 ? this.ipoSummaries[0].business_license_file : ''
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
      'getIpoSummaries',
      'getHistoricalIpo',
      'calculateIpo',
      'makeIpoReports',
      'clearIpoResult',
      'updateSelectedIpo',
    ]),
    refreshIpoSummaries () {
      this.getIpoSummaries()
      this.getHistoricalIpo()
      this.getIpos().then(() => {
        this.updateSelectedIpo()
      })
    },
    calculate () {
      let aggregateAccount = this.ipoSummaries.find((summary) => summary.account_type === '집합')
      let ventureAccount = this.ipoSummaries.find((summary) => summary.account_type === '벤처')
      let highYieldAccount = this.ipoSummaries.find((summary) => summary.account_type === '하이일드')
      let selfAccount = this.ipoSummaries.find((summary) => summary.account_type === '고유')

      aggregateAccount = aggregateAccount !== undefined ? aggregateAccount : []
      ventureAccount = ventureAccount !== undefined ? ventureAccount : []
      highYieldAccount = highYieldAccount !== undefined ? highYieldAccount : []
      selfAccount = selfAccount !== undefined ? selfAccount : []

      let params = {
        ipo_id: this.selectedIpoId,
        capital: this.selectedIpo.capital,
        owned_capital: this.selectedIpo.owned_capital,
        total_assets_amount: this.selectedIpo.total_assets_amount,
        aggregate_account: aggregateAccount,
        venture_account: ventureAccount,
        highyield_account: highYieldAccount,
        self_account: selfAccount,
        new_shares_ratio: this.selectedIpo.new_shares_ratio,
        old_shares_ratio: this.selectedIpo.old_shares_ratio,
        broker_id: this.selectedBrokerId,
        predicted_date: this.selectedPredictedDate,
        subscription_date: this.selectedSubscriptionDate,
        is_venture: this.selectedIpo.is_venture,
        is_listing_transfer: this.selectedIpo.is_listing_transfer,
      }

      this.calculateIpo(params).then(() => {
        this.getIpoSummaries()
        this.getHistoricalIpo()
      })
    },
    makeReports () {
      this.makeIpoReports(this.selectedIpoId).then(resp => {
        if (resp === 'fail') {
          this.$message.error('파일생성 실패')
        } else {
          this.getIpoSummaries().then(() => {
            this.$message({
              message: '파일생성 완료',
              type: 'success'
            })
          })
        }
      })
    },
    clearReports () {
      this.clearIpoResult(this.selectedIpoId).then(resp => {
        if (resp === 'fail') {
          this.$message.error('IPO결과 삭제 실패')
        } else {
          this.$message({
            message: 'IPO결과 삭제 완료',
            type: 'success'
          })
        }
      })

      this.refreshIpoSummaries()
    },
    fileURL (url) {
      return (url) ? decodeURI(url) : ''
    },
    ipoFundLists () {
      this.$store.dispatch('modalOpen', { height: 80, width: 70 })
    },
  },
}
</script>
